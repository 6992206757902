@import '../pages/page-include';
@import './components/banner-overlay-content';

nav {
    position: absolute;
    z-index: 2;
    width: 100%;
}

.hero-banner {
    width: 100%;
    display: block;
    position: relative;
    overflow: hidden;

    // Add new native Aspect Ratio with @support
    @supports (aspect-ratio: 3 / 4) {
        aspect-ratio: 3 / 4;
    }

    @include mq($not-mobile-small) {
        @supports (aspect-ratio: 3 / 1.5) {
            aspect-ratio: 3 / 1.5;
        }
    }

    @include mq($not-mobile) {
        @supports (aspect-ratio: 4 / 1.5) {
            aspect-ratio: 4 / 1.5;
        }
    }
}

.hero-banner-img {
    @include bg-cover;

    height: 100%;
}

// Animations
// Background image animation
.animate-img {
    -webkit-animation: fadeInImg 1s ease-in;
    animation: fadeInImg 1s ease-in;
    -webkit-animation-delay: 0.1;
    animation-delay: 0.1;
    -webkit-animation-fill-mode: backwards;
    animation-fill-mode: backwards;
}

@keyframes fadeInImg {
    0% {
        opacity: 0;
        -webkit-transform: scale(1.3);
        -ms-transform: scale(1.3);
        transform: scale(1.3);
    }

    100% {
        opacity: 1;
        -webkit-transform: scale(1);
        -ms-transform: scale(1);
        transform: scale(1);
    }
}

// Content block animation
.animate-content {
    -webkit-animation: fadeInText 2s ease-in;
    animation: fadeInText 2s ease-in;
    -webkit-animation-delay: 0.5;
    animation-delay: 0.5;
    -webkit-animation-fill-mode: backwards;
    animation-fill-mode: backwards;
}

@keyframes fadeInText {
    0% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}
