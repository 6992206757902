@import '../../pages/page-include';

.banner-content {
    @include absolute-full-width;
    @include global-padding;

    max-width: 1980px;
    margin: 0 auto;
    z-index: 2;
    display: flex;
    flex-direction: column;
    justify-content: center;

    @include mq($not-mobile) {
        &.center-middle {
            text-align: center;
        }

        &,
        &.center-middle,
        &.center-right,
        &.center-left {
            justify-content: center;
        }

        &.bottom-middle,
        &.top-middle {
            align-items: center;
        }

        &.center-left,
        &.bottom-left,
        &.top-left {
            align-items: flex-start;
        }

        &.center-right,
        &.bottom-right,
        &.top-right {
            align-items: flex-end;
        }

        &.bottom-right,
        &.bottom-left,
        &.bottom-middle {
            justify-content: flex-end;
            margin-bottom: $padding-xl;
        }

        &.top-right,
        &.top-left,
        &.top-middle {
            justify-content: flex-start;
            margin-top: $padding-xl;
        }
    }

    &.right-mobile {
        @include mq($mobile-max) {
            text-align: right;
        }
    }

    &.left-mobile {
        @include mq($mobile-max) {
            text-align: left;
        }
    }

    &.center-mobile {
        @include mq($mobile-max) {
            text-align: center;
        }
    }
}

.banner-title,
.banner-subtitle {
    word-break: break-word;
}

// Banner Title font size
.banner-title {
    &.small {
        font-size: 30px;
        line-height: 34px;

        @include mq($mobile-max) {
            font-size: 16px;
            line-height: 20px;
        }
    }

    &.medium {
        font-size: 36px;
        line-height: 40px;

        @include mq($mobile-max) {
            font-size: 22px;
            line-height: 30px;
        }
    }

    &.large {
        font-size: 46px;
        line-height: 50px;

        @include mq($mobile-max) {
            font-size: 30px;
            line-height: 34px;
        }
    }

    &.extra-large {
        font-size: 56px;
        line-height: 60px;

        @include mq($mobile-max) {
            font-size: 38px;
            line-height: 42px;
        }
    }
}

.banner-subtitle {
    margin-top: 15px;

    &.small {
        font-size: 18px;
        line-height: 25px;

        @include mq($mobile-max) {
            font-size: 16px;
            line-height: 20px;
        }
    }

    &.medium {
        font-size: 30px;
        line-height: 35px;

        @include mq($mobile-max) {
            font-size: 18px;
            line-height: 25px;
        }
    }

    &.large {
        font-size: 40px;
        line-height: 45px;

        @include mq($mobile-max) {
            font-size: 30px;
            line-height: 35px;
        }
    }

    &.extra-large {
        font-size: 45px;
        line-height: 50px;

        @include mq($mobile-max) {
            font-size: 30px;
            line-height: 35px;
        }
    }
}

// Style buttons and text
.banner-cta {
    padding-top: $padding-s;

    .btn {
        min-width: 150px;

        &:nth-child(2) {
            @include mq($not-mobile) {
                margin-left: 20px;
            }
        }

        @include mq($mobile-max) {
            margin-bottom: 10px;
        }
    }
}

.light {
    color: $white;
}

.dark {
    color: $black;
}

// Animations
// Background image animation
.animate-img {
    -webkit-animation: fadeInImg 1s ease-in;
    animation: fadeInImg 1s ease-in;
    -webkit-animation-delay: 0.1;
    animation-delay: 0.1;
    -webkit-animation-fill-mode: backwards;
    animation-fill-mode: backwards;
}

@keyframes fadeInImg {
    0% {
        opacity: 0;
        -webkit-transform: scale(1.3);
        -ms-transform: scale(1.3);
        transform: scale(1.3);
    }

    100% {
        opacity: 1;
        -webkit-transform: scale(1);
        -ms-transform: scale(1);
        transform: scale(1);
    }
}

// Content block animation
.animate-content {
    -webkit-animation: fadeInText 2s ease-in;
    animation: fadeInText 2s ease-in;
    -webkit-animation-delay: 0.5;
    animation-delay: 0.5;
    -webkit-animation-fill-mode: backwards;
    animation-fill-mode: backwards;
}

@keyframes fadeInText {
    0% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}
